<template>
  <b-modal id="comment-modal" title="مشاهده نظر" hide-footer size="lg">
    <div class="my-2">
      <span class="font-bold">
        کاربر :
      </span>
      <span v-if="comment.creator">
        {{
          comment.creator.first_name && comment.creator.last_name
            ? comment.creator.first_name + " " + comment.creator.last_name
            : comment.creator.mobile
        }}
      </span>
    </div>
    <div class="my-2">
      <span class="font-bold">
        محصول :
      </span>
      <span>
        {{ comment.product ? comment.product.title : "" }}
      </span>
    </div>
    <div class="my-2 d-flex align-items-center" v-if="comment.product">
      <span class="font-bold">
        امتیاز :
      </span>
      <span class="d-block" style="width:200px">
        <b-form-rating
          v-model="comment.rate"
          variant="warning"
          :readonly="true"
          class="border-0 comment-rate pb-0 pt-1 "
        >
        </b-form-rating>
      </span>
    </div>
    <div class="my-2">
      <span class="font-bold">
        نظر :
      </span>
      <span>
        {{ comment.body }}
      </span>
    </div>

    <div class="my-2">
      <span class="font-bold">
        پاسخ دادن به نظر :
      </span>
      <form action="" class="d-flex  my-2">
        <textarea
          v-model="replayAdmin"
          class="border  p-2"
          name=""
          id=""
          cols="30"
          rows="3"
        ></textarea>
        <button @click="replay($event)" class="btn btn-info mx-2 align-self-end">  
  ثبت پاسخ ادمین  
</button>
      </form>
    </div>

    <div class="mt-4 mb-1 text-center">
      <span id="add-btn" class="d-flex justify-content-center gap-3 flex-wrap ">
        <button
          @click="changeStatus('approved')"
          class="btn btn-success"
          :disabled="
            !hasPermission('modify_productComment') ||
            disabled ||
            comment.status == 'approved'
              ? true
              : false
          "
        >
          تایید نظر
        </button>
        <button
          @click="changeStatus('reject')"
          class="btn btn-danger"
          :disabled="
            !hasPermission('modify_productComment') ||
            disabled ||
            comment.status == 'reject'
              ? true
              : false
          "
        >
          رد نظر
        </button>
        <button
          @click="changeStatus('pending')"
          class="btn btn-warning"
          :disabled="
            !hasPermission('modify_productComment') ||
            disabled ||
            comment.status == 'pending'
              ? true
              : false
          "
        >
          در انتظار بررسی
        </button>
       
      </span>
      <b-tooltip
        v-if="!hasPermission('modify_productComment')"
        target="add-btn"
        triggers="hover"
      >
        شما به این بخش دسترسی ندارید
      </b-tooltip>
    </div>
  </b-modal>
</template>
<script>
import ProductCommentShowImpl from "./ProductCommentShowImpl";

export default {
  extends: ProductCommentShowImpl,
};
</script>
<style>
#add-btn {
  gap: 10px;
}
</style>
