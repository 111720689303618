import Loading from "@@/core/components/shared/Loading/Loading";
import OrderStatus from "@@/core/components/order/OrderStatus/OrderStatus";
import mixins from "@@/core/mixins/mixins";
import WalletSvg from "@@/core/components/svgs/WalletSvg";

import {
  BTooltip,
  BButton,
  BCard,
  BTable,
  BCollapse,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BModal,
  VBModal,
  VBToggle,
} from "bootstrap-vue";

export default {
  name: "order",
  components: {
    Loading,
    OrderStatus,
    WalletSvg,
    BTooltip,
    BButton,
    BCard,
    BTable,
    BCollapse,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BModal,
  },
  directives: {
    "b-modal": VBModal,
    "b-toggle": VBToggle,
  },
  mixins: [mixins],
  data() {
    return {
      url: "admin/get-unverified-invoices",
      items: [],
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "amount",
          label: "مبلغ",
        },
        {
          key: "date",
          label: "تاریخ",
        },
        {
          key: "authority",
          label: "authority",
        },
      ],
 
    
    };
  },
  created() {
    this.initData()
  },

  methods: {
    initData() {
      this.$axios.get(this.url).then((res) => {
        this.items = res.data.data.response.data.authorities;
      });
    },
  },
};
