import {
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
  BModal,
  BFormCheckbox,
  BTable,
  BCol,
  BRow,
} from "bootstrap-vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import configProvider from "@@/core/configProvider";
import multiselect from "vue-multiselect";
export default {
  components: {
    multiselect,
    Treeselect,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BModal,
    BFormCheckbox,
    BTable,
    BCol,
    BRow,
  },
  configProvider,
  props: {
    linkables: Array,
    group: Number,
    mode: {
      default: "create",
      type: String,
    },
    menu_item: Object,
    options: Array,
    labelMultiselect: String,
  },
  watch: {
    menu_item: {
      immediate: true,
      handler: function(menu_item) {
        this.linkUrl = Boolean(menu_item.link);
      },
    },
  },
  data() {
    return {
      disabled: false,
      url: "",
      models: [],
      modelLable: this.labelMultiselect,
      linkUrl: false,
      shouldShowCount: false,
      parentId: this.menu_item ? this.menu_item.parent_id : 0,
    };
  },
  computed: {
    realOptions() {
      let temp = this.$root.addLabel(this.options);
      temp.unshift({
        label: "ندارد",
        children: [],
        id: 0,
      });
      return temp;
    },
  },
  methods: {
    async submit(e) {
      e.preventDefault();
      this.disabled = true;
      try {
        this.url =
          this.mode == "edit"
            ? `admin/menu_items/${this.menu_item.id}?widgets=["menus:${this.group}"]`
            : `admin/menu_items?widgets=["menus:${this.group}}"]`;
        let formData = new FormData(this.$refs.form);
        this.mode == "edit" ? formData.append("_method", "PUT") : "";
        formData.append("group_id", this.group);
        formData.append("status", this.menu_item.status == 1 ? 1 : 0);
        formData.append("new_tab", this.menu_item.new_tab == 1 ? 1 : 0);
        if (formData.get("linkable_type")) {
          formData.set("link", "");
        }

        const response = await this.$axios.post(this.url, formData);

        this.$root.notify(response.data.message, "success");
        this.$parent._data.menu_items = response.data.data.widgets.menu_items;
        this.$root.$emit("bv::hide::modal", "menu-modal");
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.disabled = false;
      }
    },
    loadModels(item) {
      console.log(item);
      this.menu_item.linkable_id = null;
      if (item) {
        this.models = item.models;
        console.log(this.models);
        if (this.models != null && this.models != "" && this.models[0].title) {
          console.log(1);
          this.modelLable = "title";
          this.linkUrl = false;
        } else if (
          this.models != null &&
          this.models != "" &&
          this.models[0].name
        ) {
          console.log(2);
          this.modelLable = "name";
          this.linkUrl = false;
        } else if (this.models != null && this.models == "link_url") {
          this.linkUrl = true;
        } else {
          this.linkUrl = false;
        }
      }
    },
  },
};
