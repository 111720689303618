import {BTooltip, BFormRating, BModal} from "bootstrap-vue";

export default {
  components: {
    BTooltip, BFormRating, BModal
  },
  props: {
    comment: Object,
    url: {
      default: 'admin/product-comment',
      type: String
    }
  },
  data() {
    return {
      disabled: false,
      replayAdmin: null,
    }
  },
  methods: {
    async changeStatus(status) {
      this.disabled = true
      try {
        const response = await this.$axios.post(this.url, {
          id: this.comment.id,
          status: status
        })

        if (this.$parent._data.items && this.$parent._data.items.data) {
          this.$parent._data.items.data[this.comment.index].status = status
        }
        this.$emit('statusChanged', {id: this.comment.id, status})
        this.$root.notify(response.data.message, 'success')
        this.$root.$emit('bv::hide::modal', 'comment-modal')
      } catch (error) {
        this.$root.notify(error)
      } finally {
        this.disabled = false
      }
    },
    async replay(event) {  
      // جلوگیری از رفتار پیش‌فرض فرم  
      event.preventDefault();  
      
      await this.$axios  
        .post(`admin/product-comment/${this.comment.id}/answer`, {  
          body: this.replayAdmin,  
          status: "pending",  
        })  
        .then((response) => {  
          swal({  
            icon: "success",  
            text: response.data.message,  
            buttons: "متوجه شدم",  
          });  
        })  
        .catch((error) => {  
          swal({  
            icon: "error",  
            text: error.response.data.message,  
            buttons: "متوجه شدم",  
          });  
        }); 
         this.$parent.loadItems();
      this.$root.$emit("bv::hide::modal", "comment-modal");

        //  this.loadItems();

    },  
  }
}
