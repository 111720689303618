<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold py-3">سفارشات تایید نشده</h5>
        <div style="flex:1"></div>
      </div>

      <div class="card-body pt-1">
        <div>
          <b-table
            responsive
            hover
            bordered
            :items="items"
            :fields="fields"
            :style="disabled && items && items.data ? 'opacity: 0.7' : ''"
          >
          <template #cell(index)="row">
            {{ row.index+1  }}
          </template>
          <template #cell(date)="row">
            {{ row.item.date | persianDate }}
          </template>

            <template #cell(amount)="row">
              {{ row.item.amount | price }}
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination
            v-else-if="items.data != ''"
            :disabled="disabled"
            :limit="2"
            :data="items"
            @pagination-change-page="changePage"
          >
          </pagination>
          <div v-else class="alert alert-danger">
            موردی یافت نشد
          </div>
        </div>
      </div>

    </div>
  </main>
</template>
<script>
import unverifiedInvoicesImpl from "@@/core/pages/unverifiedInvoices/unverifiedInvoicesImpl";
export default {
  extends: unverifiedInvoicesImpl,
};
</script>
<style scoped>
.badge {
  font-size: 14px;
}
.orders-tooltip {
  font-size: 14px;
  position: absolute;
  padding: 10px 20px;
  right: -4px;
  top: 50%;
  transform: translate(100%, -50%);
  border-radius: 5px;
  background: #024c5c;
  display: flex;
  flex-direction: column;
  color: white;
}

.orders-tooltip span {
  margin: 4px 0;
}

.orders-tooltip:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -6px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 7px solid #0f6674;
}
</style>
