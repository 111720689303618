import {BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BModal} from "bootstrap-vue";
import datePicker from "vue-persian-datetime-picker";

export default {
  components: {datePicker,
    BModal, BFormGroup, BFormInput, BFormSelect, BFormSelectOption
  },
  props: {
    coupon: Object,
    mode: {
      default: 'create',
      type: String
    },
    url: String,
  },
  data() {
    return {
      disabled: false,
      startDate: '',
      endDate: '',
    }
  },
  methods: {
    async submit(e) {
      e.preventDefault()
      this.disabled = true
      try {
        const response = await this.$axios.post(this.url, {
          title: this.$refs.title.$el.value,
          code: this.$refs.code.$el.value,
          start_date: this.coupon.start_date ? window.moment(this.coupon.start_date, 'YYYY.MM.DD HH:mm').format('YYYY-MM-DD HH:mm') : this.coupon.start_date,
          end_date: this.coupon.end_date ? window.moment(this.coupon.end_date, 'YYYY.MM.DD HH:mm').format('YYYY-MM-DD HH:mm') : this.coupon.end_date,
          type: this.coupon.type,
          amount: this.coupon.type == 'percentage' ? this.$refs.amount.$el.value : this.coupon.amount,
          usage_limit: this.$refs.usage_limit.$el.value,
          usage_per_user_limit: this.$refs.user_limit.$el.value,
          min_order_amount: this.$refs.min_order_amount.$el.value ? this.$refs.min_order_amount.$el.value.replaceAll(',', '') : '',
          _method: this.mode == 'edit' ? 'PUT' : 'post'
        });
        this.$root.notify(response.data.message, 'success')
        if (this.mode == 'edit') {
          this.$root.$set(this.$parent._data.items.data, this.coupon.index, response.data.data.coupon);
        } else {
          this.$parent._data.items.data.unshift(response.data.data.coupon)
        }
        this.$root.$emit('bv::hide::modal', 'coupon-modal')
      } catch (error) {
        this.$root.notify(error)
      }
      this.disabled = false
    },
  }
}
